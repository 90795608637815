<template>
  <div ref="element" :class="{ 'animate-trigger': isVisible }" class="base-animated-container">
    <slot></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  // 动画延迟触发时间（毫秒）
  delay: {
    type: Number,
    default: 0
  },
  // 是否在视口中就立即触发动画
  immediate: {
    type: Boolean,
    default: true
  },
  // 触发动画的阈值（0-1），表示元素进入视口多少比例时触发
  threshold: {
    type: Number,
    default: 0.1
  }
});

const element = ref(null);
const isVisible = ref(false);
const hasInitialCheck = ref(false);

// 检查元素是否在视口中
const checkVisibility = () => {
  if (!element.value) return;

  const rect = element.value.getBoundingClientRect();
  // 计算元素有多少比例在视口中
  const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
  const visibleWidth = Math.min(rect.right, window.innerWidth) - Math.max(rect.left, 0);
  const visibleArea = visibleHeight * visibleWidth;
  const totalArea = rect.width * rect.height;
  const visibleRatio = visibleArea / totalArea;
  
  // 只有当元素有足够部分在视口中时才触发动画
  if (visibleRatio >= props.threshold && visibleRatio > 0) {
    // 如果设置了延迟，则延迟显示
    if (props.delay && props.delay > 0) {
      setTimeout(() => {
        isVisible.value = true;
      }, props.delay);
    } else {
      isVisible.value = true;
    }

    // 如果已经可见并且设置为立即触发，则移除滚动监听
    if (props.immediate && isVisible.value) {
      window.removeEventListener('scroll', checkVisibility);
    }
  }
};

// 在组件挂载后添加滚动监听
onMounted(() => {
  // 确保DOM更新完成后再检查
  nextTick(() => {
    // 添加一个小延时确保元素完全渲染
    setTimeout(() => {
      hasInitialCheck.value = true;
      checkVisibility();
    }, 100);
  });
  
  // 添加滚动事件监听
  window.addEventListener('scroll', checkVisibility);
});

// 组件卸载时移除监听
onUnmounted(() => {
  window.removeEventListener('scroll', checkVisibility);
});
</script>

<style scoped>
/* 初始状态时，元素是不可见的，但不影响背景色和其他样式 */
:deep(.animated) {
  opacity: 0;
  /* 不设置背景色和其他可能影响原样式的属性 */
}

/* 触发动画的基本设置 */
.animate-trigger :deep(.animated) {
  animation-duration: 1s;
  animation-fill-mode: both;
  opacity: 1; /* 确保动画完成后元素是可见的 */
}

/* 定义各种动画类型 */
.animate-trigger :deep(.animated.fadeInLeft) {
  animation-name: fadeInLeft;
}

.animate-trigger :deep(.animated.fadeInRight) {
  animation-name: fadeInRight;
}

.animate-trigger :deep(.animated.fadeInUp) {
  animation-name: fadeInUp;
}

.animate-trigger :deep(.animated.fadeInDown) {
  animation-name: fadeInDown;
}

/* 动画容器样式 - 确保不影响背景色和其他样式 */
.base-animated-container {
  background-color: transparent !important; /* 强制透明背景 */
  padding: 0;
  margin: 0;
  border: none;
  display: block;
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
}
</style>
